<svelte:window on:load={onMount} />

<script>
    export let content;
    let letters = [];
    let onMount = () => {
        letters = content.split('');
    };
</script>

<style>
.animate {
    opacity: 0;
    animation: expand;
    animation-duration: 10s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}

@keyframes expand {
    0% {
        position: relative;
        top: -100px;
        opacity: 0;
    }
    7%, 90% {
        position: relative;
        top: 0px;
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

</style>
<div>
    <h1>
        {#each letters as letter, i}
        <span class="animate" style="animation-delay: {i / 25}s;">{letter}</span>
        {/each}
    </h1>
</div>
