<script>
    import { onMount } from 'svelte';

    export let fullurl;
    export let description = '';

    let hidden_cls = 'hidden';

    let visible = false;
    let gone = true;
    let gone_timeout = 500; // ms

    const toggle = () => {
        visible = !visible;

        if (visible) { gone = false };
        hidden_cls = visible ? '' : 'hidden';

        setTimeout(() => {
            if (!visible) { gone = true; }
        }, gone_timeout);
        fixScroll();
    };

    let fixScroll = () => {
    };

    onMount(()=>{
        let defaultOverflow = document.body.style.overflow;
        fixScroll = () => {
            if (visible) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = defaultOverflow;
            }
        };
    });

</script>

<div on:click={toggle}>
    <div class="wrapped">
        <slot/>
    </div>
</div>

{#if !gone}
<div class="cover {hidden_cls}" on:click={toggle}>
    <div class="svelte-lightbox-overlay" on:click={toggle}>
        <div class="svelte-lightbox-header">
            <button on:click={toggle}>
                ×
            </button>
        </div>
        <div class="svelte-lightbox-body">
            <img src={fullurl}/>
        </div>
        <div class="svelte-lightbox-footer">
            <h3>
                {description}
            </h3>
        </div>
    </div>
</div>
{/if}

<style>
    .wrapped:hover {
        filter: brightness(50%);
        transition: 0.5s;
    }

    .hidden {
        opacity: 0;
    }
    .cover {
        transition: opacity 0.3s; /* make sure this is smaller than the gone timeout variable */
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: #000000aa;
    }
    .svelte-lightbox-overlay {
        position: relative;
        background-color: transparent;
        margin: auto;
        padding: 0;
        width: 90%;
    }
    .svelte-lightbox-body {
        background-color: transparent;
        width: auto;
        height: auto;
        text-align: center;
    }
    .svelte-lightbox-body > img {
        height: 100%;
        object-fit: contain;
        max-height: 90vh;
        max-width: 75vw;
    }
    .svelte-lightbox-footer {
        background-color: transparent;
        width: inherit;
        height: auto;
    }
    .svelte-lightbox-unselectable {
        user-select: none;
        pointer-events: none;
    }
    h3 {
        color: white;
    }
    button {
        background: transparent;
        font-size: 2rem;
        border: none;
        color: white;
    }
    button:hover {
        color: lightgray;
        outline: none;
    }
    button:focus{
        color: lightgray;
        outline: none;
    }
</style>
