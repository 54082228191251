<script>
    export let title;
    export let imgsrc;
    export let alt;
</script>

<style>
img {
    object-fit: cover;
    border-radius: 20px 20px 0 0;
    width: 100%;
    pointer-events: none;
}
.card {
    border-radius: 20px;
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    margin: 5px;
}
.content {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
}
.text-limited {
    max-height: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: max-height 0.7s;
}
.text-limited:hover {
    max-height: 1000px;
    overflow: visible;
}

</style>

<div class="card">
    <img width=350 height=250 src="{imgsrc}" alt="{alt}"/>
    <div class="content">
        <h2>{title}</h2>
        <div class="text-limited">
            <slot></slot>
        </div>
    </div>
</div>
