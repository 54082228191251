// This is a main JavaScript file that will ll be compiled into /javascripts/site.js
import LightBox from '../components/lightbox-img.svelte';
import Card from '../components/exhibition-card.svelte';
import TextAnim from '../components/text-anim.svelte';

import 'macro-carousel';

import { SlIconButton, SlTabGroup, SlTab, SlTabPanel } from '@shoelace-style/shoelace';

try {
    customElements.define('sl-icon-button', SlIconButton);
    customElements.define('sl-tab-group', SlTabGroup);
    customElements.define('sl-tab-panel', SlTabPanel);
    customElements.define('sl-tab', SlTab);
} catch(e) {
    console.warn('error', e);
}

try {
    console.log('svelte components', LightBox, Card, TextAnim);
    customElements.define('lightbox-img', LightBox);
    customElements.define('exhibition-card', Card);
    customElements.define('text-anim', TextAnim);
} catch(e) {
    console.warn('error', e);
}


document.addEventListener('DOMContentLoaded', function() {
    const menus = document.querySelectorAll('.navbar-burger');
    const dropdowns = document.querySelectorAll('.navbar-menu');

    if (menus.length && dropdowns.length) {
        for (var i = 0; i < menus.length; i++) {
            menus[i].addEventListener('click', function() {
                console.log('click!');
                for (var j = 0; j < dropdowns.length; j++) {
                    dropdowns[j].classList.toggle('hidden');
                }
            });
        }
    }
});
